/* Core */
import "./bootstrap";
import "./utils/gesture";
import "./utils/lazy-video";
import "./utils/vidstack";

import {
    Alpine,
    Livewire,
} from "../../vendor/livewire/livewire/dist/livewire.esm";

import ui from "@alpinejs/ui";
import Tooltip from "@ryangjchandler/alpine-tooltip";
import hovery from "./alpine/data/hovery";
import mapboxStatic from "./alpine/data/mapbox-static";
import uppy from "./alpine/data/uppy";

import "tippy.js/animations/shift-away-subtle.css";
import "tippy.js/dist/tippy.css";

Alpine.plugin(ui);
Alpine.plugin(
    Tooltip.defaultProps({
        animation: "shift-away-subtle",
    })
);

Alpine.data("uppy", uppy);
Alpine.data("mapboxStatic", mapboxStatic);
Alpine.data("hovery", hovery);

Livewire.start();
