export default ({ ...others } = {}) => ({
    width: 0,
    height: 0,
    zRotation: 0,
    ...others,
    init() {
        const rect = this.$el.getBoundingClientRect();
        this.width = rect.width;
        this.height = rect.height;
    },
    mousemove(event) {
        const xVal = event.layerX;
        const yVal = event.layerY;

        const multiplier = 50;

        const yRotation = (multiplier * (xVal - this.width / 2)) / this.width;

        const xRotation =
            -multiplier * ((yVal - this.height / 2) / this.height);

        const transform = `perspective(500px) scale(1) rotateZ(${this.zRotation}deg) rotateX(${xRotation}deg) rotateY(${yRotation}deg) `;

        this.$el.style.transition = "transform linear 200ms";
        this.$el.style.transform = transform;
    },
    mouseleave(event) {
        this.$el.style.transition = "";
        this.$el.style.transform = "";
    },
    trigger: {
        ["x-on:mousemove.throttle.200ms"](event) {
            return this.mousemove(event);
        },
        ["x-on:mouseleave"](event) {
            return this.mouseleave(event);
        },
    },
});
