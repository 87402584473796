import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import en_US from "@uppy/locales/lib/en_US";
import fr_FR from "@uppy/locales/lib/fr_FR";
import getDroppedFiles from "@uppy/utils/lib/getDroppedFiles";

import slugify from "@sindresorhus/slugify";
import ImageEditor from "@uppy/image-editor";
import Tus from "@uppy/tus";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";

export default ({
    enabled = true,
    locale = "en",
    options,
    dashboard,
    metadata = {},
    tus,
    imageEditor = {
        cropperOptions: {},
    },
    ...props
} = {}) => {
    return {
        ...props,
        enabled,
        uppy: null,
        // Custom dropzone
        isDropping: false,
        dropOffsetX: 0,
        dropOffsetY: 0,
        isDraggingFile(event) {
            return event.dataTransfer.types.some((type) => type === "Files");
        },
        onDragover(event) {
            if (!this.enabled) {
                return;
            }

            if (!this.isDraggingFile(event)) {
                return;
            }

            if (!this.isDropping) {
                this.isDropping = true;
            }
            this.dropOffsetX = event.offsetX;
            this.dropOffsetY = event.offsetY;
        },
        addFiles(files) {
            const descriptors = files.map((file) => ({
                source: options.id ?? "user",
                name: file.name,
                type: file.type,
                data: file,
                meta: {
                    // path of the file relative to the ancestor directory the user selected.
                    // e.g. 'docs/Old Prague/airbnb.pdf'
                    relativePath:
                        file.relativePath || file.webkitRelativePath || null,
                },
            }));

            try {
                this.uppy.addFiles(descriptors);
            } catch (err) {
                this.uppy.log(err);
            }
        },
        async handleFilesDrop(event) {
            if (!this.enabled) {
                return;
            }

            if (!this.isDraggingFile(event)) {
                return;
            }

            getDroppedFiles(event.dataTransfer);
            const files = await getDroppedFiles(event.dataTransfer);

            if (files.length > 0) {
                this.uppy.log("[Dashboard] Files dropped");
                this.addFiles(files);
            }

            this.openUppy();
            this.isDropping = false;
        },
        // Custom dropzone

        closeUppy(clear = false) {
            if (!this.enabled) {
                return;
            }
            const dashboard = this.uppy.getPlugin("Dashboard");

            if (dashboard.isModalOpen()) {
                dashboard.closeModal();
            }

            if (clear) {
                this.uppy.cancelAll();
            }
        },
        openUppy(clear = false) {
            if (!this.enabled) {
                return;
            }
            const dashboard = this.uppy.getPlugin("Dashboard");

            if (!dashboard.isModalOpen()) {
                dashboard.openModal();
            }

            if (clear) {
                this.uppy.cancelAll();
            }
        },
        init() {
            this.uppy = new Uppy({
                debug: false,
                locale: locale === "fr" ? fr_FR : en_US,
                onBeforeFileAdded: (file, files) => {
                    file.name =
                        slugify(file.name.replace(/\.[^/.]+$/, "")) +
                        "." +
                        file.extension;
                    return !Object.hasOwn(files, file.id);
                },
                ...options,
            })
                .use(Dashboard, {
                    inline: false,
                    closeModalOnClickOutside: true,
                    showProgressDetails: true,
                    singleFileFullScreen: false,
                    fileManagerSelectionType: "both",
                    disablePageScrollWhenModalOpen: false,
                    proudlyDisplayPoweredByUppy: false,
                    ...dashboard,
                })
                .use(ImageEditor, {
                    target: Dashboard,
                    cropperOptions: {
                        viewMode: 1,
                        autoCrop: true,
                        autoCropArea: 1,
                        ...imageEditor.cropperOptions,
                    },
                    ...imageEditor,
                })
                .use(Tus, {
                    endpoint: import.meta.env.VITE_TUS_ENDPOINT,
                    limit: import.meta.env.VITE_TUS_LIMIT, // Limit the amount of uploads going on at the same time (number, default: 20).
                    // chunkSize: import.meta.env.VITE_TUS_CHUNK_SIZE,
                    waitForEncoding: true,
                    ...tus,
                });

            this.uppy.setMeta(metadata);

            this.uppy.on("complete", (result) => {
                this.$dispatch("uppy-completed", result);
            });

            this.uppy.on("file-editor:complete", (updatedFile) => {
                this.$dispatch("file-editor-completed", updatedFile);
            });

            this.uppy.on("error", (error) => {
                if (error.assembly) {
                    console.error(
                        `Assembly ID ${error.assembly.assembly_id} failed!`
                    );
                    console.log(error.assembly);
                }
            });
        },
    };
};
