export default ({
    lon = 2.3991,
    lat = 42.7599,
    zoom = null,
    bearing = null,
    pitch = null,
    bbox = null,
    resizeInterval = 50,
    access_token,
    ...others
}) => ({
    height: 0,
    width: 0,
    lon,
    lat,
    zoom,
    bearing,
    pitch,
    bbox,
    get map() {
        const w = Math.round(Math.min(1280, this.width));
        const h = Math.round(Math.min(1280, this.height));

        if (!w || !h) {
            return "";
        }

        if (this.bbox !== null) {
            return `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/[${this.bbox}]/${w}x${h}@2x?access_token=${access_token}&logo=false`;
        }

        if (this.lon !== null && this.lat !== null) {
            const params = [
                this.lon,
                this.lat,
                this.zoom,
                this.bearing,
                this.pitch,
            ]
                .filter((value) => value !== null)
                .join(",");
            return `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/${params}/${w}x${h}@2x?access_token=${access_token}&logo=false`;
        }

        return `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/auto/${w}x${h}@2x?access_token=${access_token}&logo=false`;
    },
    background: {
        ["x-bind:style"]() {
            return {
                backgroundImage: `url(${this.map})`,
            };
        },
        ["x-resize"]() {
            if (!this.width) {
                this.width = this.$width;
            } else if (Math.abs(this.$width - this.width) > resizeInterval) {
                this.width = this.$width;
            }

            if (!this.height) {
                this.height = this.$height;
            } else if (Math.abs(this.$height - this.height) > resizeInterval) {
                this.height = this.$height;
            }
        },
    },
    ...others,
});
